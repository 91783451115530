import { ChainId } from '@pancakeswap/sdk'
import { bsc, bscTest, ethwMainnet, goerli, mainnet, onusMainnet, onusTestnet } from '@pancakeswap/wagmi/chains'
import { BinanceWalletConnector } from '@pancakeswap/wagmi/connectors/binanceWallet'
import { UniPassConnector } from "@unipasswallet/wagmi-connector"
import { PUBLIC_NODES } from 'config/nodes'
import memoize from 'lodash/memoize'
import { configureChains, createClient } from 'wagmi'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { LedgerConnector } from 'wagmi/connectors/ledger'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { SequenceConnector } from '@0xsequence/wagmi-connector'
import { TrustWalletConnector } from "../../packages/wagmi/connectors/trustWallet/trustWallet";

const CHAINS = [bsc, bscTest, goerli, ethwMainnet, onusTestnet, onusMainnet]

// get most configs chain nodes length
const mostNodesConfig = Object.values(PUBLIC_NODES).reduce((prev, cur) => {
    return cur.length > prev ? cur.length : prev
}, 0)

export const { provider, chains } = configureChains(
    CHAINS,
    Array.from({ length: mostNodesConfig })
        .map((_, i) => i)
        .map((i) => {
            return jsonRpcProvider({
                rpc: (chain) => {
                    if (process.env.NODE_ENV === 'test' && chain.id === mainnet.id && i === 0) {
                        return { http: 'https://cloudflare-eth.com' }
                    }
                    return PUBLIC_NODES[chain.id]?.[i]
                        ? {
                            http: PUBLIC_NODES[chain.id][i],
                        }
                        : null
                },
            })
        }),
)

export const injectedConnector = new InjectedConnector({
    chains,
    options: {
        shimDisconnect: false,
    },
})

export const coinbaseConnector = new CoinbaseWalletConnector({
    chains,
    options: {
        appName: 'CCQ',
        appLogoUrl: 'https://tss.org.vn/wp-content/uploads/2022/06/logotss-4.png',
    },
})

export const walletConnectConnector = new WalletConnectConnector({
    chains,
    options: {
        showQrModal: !false,
        projectId: '2011db64aa69e6deb4a8010bfcb3c9c2',
    },
})

export const walletConnectNoQrCodeConnector = new WalletConnectConnector({
    chains,
    options: {
        showQrModal: false,
        projectId: '2011db64aa69e6deb4a8010bfcb3c9c2',
    },
})

export const metaMaskConnector = new MetaMaskConnector({
    chains,
    options: {
        shimDisconnect: false,
    },
})


const ledgerConnector = new LedgerConnector({
    chains,
    options: {},
})

const uniPass: any = new UniPassConnector({
    options: {
        chainId: ChainId.BSC,
        returnEmail: false,
        appSettings: {
            appName: "CCQ",
        },
    },
});

const sequenceConnector = new SequenceConnector({
    chains,
    options: {
        connect: {
            app: 'CCQ',
            networkId: ChainId.BSC
        }
    }
})

export const trustWalletConnector = new TrustWalletConnector({
    chains,
    options: {
      shimDisconnect: false,
      shimChainChangedDisconnect: true,
    },
})

export const bscConnector = new BinanceWalletConnector({ chains })


export const noopStorage = {
    getItem: (_key) => '',
    setItem: (_key, _value) => null,
    removeItem: (_key) => null,
}

export const client = createClient({
    autoConnect: false,
    provider,
    connectors: [
        metaMaskConnector,
        injectedConnector,
        coinbaseConnector,
        walletConnectConnector,
        bscConnector,
        ledgerConnector,
        uniPass,
        sequenceConnector,
        trustWalletConnector
    ],
})

export const CHAIN_IDS = chains.map((c) => c.id)

export const isChainSupported = memoize((chainId: number) => (CHAIN_IDS as number[]).includes(chainId))
export const isChainTestnet = memoize((chainId: number) => {
    const found = chains.find((c) => c.id === chainId)
    return found ? 'testnet' in found : false
})
