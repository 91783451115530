import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'
import {
    Box,
    Flex,
    LogoutIcon,
    RefreshIcon,
    UserMenu as UIKitUserMenu,
    UserMenuItem,
    UserMenuVariant,
    useModal
} from '@pancakeswap/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Trans from 'components/Trans'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useAuth from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { usePendingTransactions } from 'state/transactions/hooks'
import { useAccount } from 'wagmi'
import WalletUserMenuItem from './WalletUserMenuItem'
import WalletModal, { WalletView } from './WalletModal'

const UserMenu = () => {
    const { t } = useTranslation()
    const { address: account } = useAccount()
    const { isWrongNetwork } = useActiveChainId()
    const { logout } = useAuth()
    const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
    const [userMenuText, setUserMenuText] = useState<string>('')
    const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')
    const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
    const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
    const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)

    const avatarSrc = undefined
    useEffect(() => {
        if (hasPendingTransactions) {
            setUserMenuText(t('%num% Pending', { num: pendingNumber }))
            setUserMenuVariable('pending')
        } else {
            setUserMenuText('')
            setUserMenuVariable('default')
        }
    }, [hasPendingTransactions, pendingNumber, t])

    const onClickWalletMenu = (): void => {
        if (isWrongNetwork) {
          onPresentWrongNetworkModal()
        } else {
          onPresentWalletModal()
        }
    }



    const UserMenuItems = () => {
        return (
            <>
                <WalletUserMenuItem isWrongNetwork={isWrongNetwork} onPresentWalletModal={onClickWalletMenu} />
                {/* <UserMenuItem as="button" disabled={isWrongNetwork} onClick={onPresentTransactionModal}>
                    {t('Giao dịch gần đây')}
                {hasPendingTransactions && <RefreshIcon spin />}
                </UserMenuItem> */}
                <UserMenuItem as="button" onClick={logout}>
                    <Flex color='primary' alignItems="center" justifyContent="space-between" width="100%">
                        {t('Ngắt kết nối ví')}
                        <LogoutIcon color='primary' />
                    </Flex>
                </UserMenuItem>
            </>
        )
    }

    if (account) {
        return (
            <UIKitUserMenu account={account} avatarSrc={avatarSrc} text={userMenuText} variant={userMenuVariable} isShowIcon={false}>
                {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
            </UIKitUserMenu>
        )
    }

    if (isWrongNetwork) {
        return (
            <UIKitUserMenu text={t('Network')} variant="danger">
                {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
            </UIKitUserMenu>
        )
    }

    return (
        <CsConnectWalletButton scale="sm">
            <Box display={['none', , , 'block']}>
                <Trans>Kết nối ví</Trans>
            </Box>
            <Box display={['block', , , 'none']}>
                <Trans>Kết nối ví</Trans>
            </Box>
        </CsConnectWalletButton>
    )
}

export default UserMenu

const CsConnectWalletButton = styled(ConnectWalletButton)`
  height: 46px;
  background: transparent;
  color: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  &:hover{
    border: 1px solid transparent;
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`
