import { ChainId, Token } from '@pancakeswap/sdk'

export const onusMainnetTokens = {
    busd: new Token(
        ChainId.ONUS,
        '0xdfB5E8a4AC08E46258A12AbE737bba5D8c452508',
        18,
        'BUSD',
        'BUSD Token',
        'https://www.paxos.com/busd/',
    ),
    usdt: new Token(
        ChainId.ONUS,
        '0xff276c6bca1F66Fd54a8915e830735D6ab0C7B09',
        18,
        'USDT',
        'USDT Token',
        'https://tether.to/',
    ),
    eth: new Token(
        ChainId.ONUS,
        '0xB4373ebB073A4DcbA47e567d075a9583Fa3C763e',
        18,
        'ETH',
        'ETH Token',
        'https://ethereum.org/en/',
    ),
    mia: new Token(
        ChainId.ONUS,
        '0x5Df107F23d3Ec5efA926B999Ce285A88955Ae56B',
        18,
        'MIA',
        'MIA Token',
        'https://miaswap.io/',
    ),
    runtogether: new Token(
        ChainId.ONUS,
        '0x9e3d30d7808C8E64dB360Abf2f32B44eB03F55d4',
        18,
        'RUN',
        'RUN Token',
    ),
    runtogetherBoxNft: new Token(
        ChainId.ONUS,
        '0x1291e12CAE4E140847Ac548D5ce79f82036DF3f5',
        0,
        'RTBN',
        'Run Together Box NFT',
        'https://runtogether.net/',
    ),
    vndc: new Token(
        ChainId.ONUS,
        '0xC1D3A18C32c42D5c033C2d4bfc151dB8fd2c9D81',
        0,
        'VNDC',
        'ONUS-peg VNDC Token',
    ),
    wrappedDetalabs2: new Token(
        ChainId.ONUS,
        '0x434Bf3131d8Ae5F076024918109B5A2BC931f494',
        0,
        'FG2023002',
        'FG2023002',
        '',
    ),
    wrappedDetalabs3: new Token(
        ChainId.ONUS,
        '0x4039c583ed1340F736aadc22457Eb283aABa7FAf',
        0,
        'FG2023003',
        'FG2023003',
        '',
    ),
    wrappedDetalabs4: new Token(
        ChainId.ONUS,
        '0x8646C4094038AeaBC368c5c4443fd2F3C1Cf0322',
        0,
        'FG2023004',
        'FG2023004',
        '',
    ),
    wrappedDetalabs5: new Token(
        ChainId.ONUS,
        '0x6613d3a132cb5f92E04B249d4AdDEA9CA02CaAD0',
        0,
        'FG2023005',
        'FG2023005',
        '',
    ),
    wrappedDetalabs6: new Token(
        ChainId.ONUS,
        '0xC6bF01546fd61F6352EedfeC2C7D5069f9501753',
        0,
        'FG2023006',
        'FG2023006',
        '',
    ),
    wrappedDetalabs7: new Token(
        ChainId.ONUS,
        '0x448F6D25eC3F02F3C6C49F7c2c4be7b750a89e73',
        0,
        'FG2023007',
        'FG2023007',
        '',
    ),
    wrappedDetalabs8: new Token(
        ChainId.ONUS,
        '0xEBFF3A4F1A6443f3CCA7abbf2BB732e4283b0e66',
        0,
        'FG2023008',
        'FG2023008',
        '',
    ),
    wrappedDetalabs9: new Token(
        ChainId.ONUS,
        '0x30CC7147dc5Bb9e8d088Ec88ec6d4fAB2C8158aa',
        0,
        'FG2023009',
        'FG2023009',
        '',
    ),
    wrappedDetalabs10: new Token(
        ChainId.ONUS,
        '0xcF699d96b1fb5352EBa07B8AB8a3382ad569E393',
        0,
        'FG20230010',
        'FG20230010',
        '',
    ),
    wrappedDetalabs11: new Token(
        ChainId.ONUS,
        '0x2B349c2816956066b41932952bF3E5ac40a1fe3b',
        0,
        'FG20230011',
        'FG20230011',
        '',
    ),
}
